import React from "react";
import { Link } from "gatsby";
import Slider from "react-slick";
import { Component } from "react";

export default class Pricing extends Component {
  state = { isActive: false };
  handleToggle = () => {
    this.setState({ isActive: !this.state.isActive });
  };
  render() {
    const isActive = this.state.isActive;
    const settings = {
      dots: true,
      infinite: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      adaptiveHeight: true,
      autoplay: false,
      swipeToSlide: true,
      arrows: false,

      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            dots: true,
          },
        },
      ],
    };
    return (
      <section className="pricing-area pt-100 pb-70 bg-f4f7fe">
        <div className="container">
          <div className="section-title">
            <h2>Cenovnik</h2>
          </div>
          {/* <div className="row d-flex justify-content-center text-center"> */}
          <div className="price-objects-div text-center">
            {/* <h5 className="align-items-center flex-column d-flex justify-content-center">Mesečno</h5>
                        <label className="align-items-center flex-column d-flex justify-content-center switch">
                            <input type="checkbox" onClick={this.handleToggle} defaultChecked />
                            <span className="slider round"></span>
                        </label>
                        <h5 className="align-items-center flex-column d-flex justify-content-center">Godišnji</h5> */}
            <h5 className="price-objects">Mesečno</h5>
            <label className="price-objects switch">
              <input
                type="checkbox"
                onClick={this.handleToggle}
                defaultChecked
              />
              <span className="slider round"></span>
            </label>
            <h5 className="price-objects">Godišnji</h5>
          </div>
          <span className="price-objects-div"></span>
          <Slider {...settings}>
            <div className="top-price">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Starter</h3>
                </div>

                <div className="price">
                  <sup>RSD</sup> 0.00 <br />
                  <sub>mesec/korisnik </sub>
                  {/* <sub><br />‎‎‏‏‎ ‎</sub> */}
                </div>

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> Do 5 korisnika
                  </li>
                  <li className="mobile-only">
                    <i className="bx bxs-badge-check"></i> 200MB Memorija po
                    korisniku
                  </li>
                  <li className="desktop-only">
                    <i className="bx bxs-badge-check"></i> 200MB Memorija po
                    korisniku
                    <br />
                    <br />
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> 20 zadataka po
                    korisniku
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Video sostanci
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Ofis paket
                  </li>
                </ul>

                <div className="btn-box">
                  <Link to="#" className="default-btn">
                    Izaberite Plan
                    <span></span>
                  </Link>
                </div>
                <sub>
                  <br />
                </sub>
              </div>
            </div>

            {/* <div className='top-price'>
                                <div className="single-pricing-box">
                                    <div className="pricing-header">
                                        <h3>Премиум</h3>
                                    </div>

                                    <div className="price">
                                        <sup>RSD</sup> {isActive ? 179 : 149}<br/> <sub>mesec/korisnik</sub>


                                    </div>

                                    <ul className="pricing-features">
                                        <li><i className='bx bxs-badge-check'></i> 1GB Memorija po korisniku</li>
                                        <li><i className='bx bxs-badge-check'></i> 100 zadataka po korisniku<span className='desktop-only'>‏‏‎&zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; &zwnj; ‎</span></li>
                                        <li><i className='bx bxs-badge-check'></i> Video sostanci*</li>
                                        <li><i className='bx bxs-x-circle red'></i> Timski izveštaj</li>
                                        <li><i className='bx bxs-x-circle red'></i> Ofis paket</li>
                                    </ul>

                                    <div className="btn-box">
                                        <Link to="#" className="default-btn">
                                            Izaberite Plan
                                            <span></span>
                                        </Link>
                                    </div>
                                <sub className='fakturirano'>{isActive ? "Fakturisano Mesečno" : "Fakturisano Godišnji"}</sub>
                                </div>
                            </div> */}

            <div className="top-price">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Standard</h3>
                </div>

                <div className="price">
                  <sup>RSD</sup> {isActive ? 379 : 319} <br />
                  <sub>mesec/korisnik</sub>
                  {/* <sub><br />  {isActive ? "Fakturisano Mesečno" : "Fakturisano Godišnji"}</sub> */}
                </div>

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> 5GB Memorija po
                    korisniku
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Neograničeno zadataka
                    po korisniku
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Video sostanci*
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Timski izveštaj
                  </li>
                  <li>
                    <i className="bx bxs-x-circle red"></i> Ofis paket
                  </li>
                </ul>

                <div className="btn-box">
                  <Link to="#" className="default-btn">
                    Izaberite Plan
                    <span></span>
                  </Link>
                </div>
                <sub className="fakturirano">
                  {isActive ? "Fakturisano Mesečno" : "Fakturisano Godišnji"}
                </sub>
              </div>
            </div>

            <div className="top-price">
              <div className="single-pricing-box">
                <div className="pricing-header">
                  <h3>Biznis</h3>
                </div>

                <div className="price">
                  <sup>RSD</sup> {isActive ? 569 : 469}
                  <br /> <sub>mesec/korisnik</sub>
                  {/* <sub><br />  {isActive ? "Fakturisano Mesečno" : "Fakturisano Godišnji"}</sub> */}
                </div>

                <ul className="pricing-features">
                  <li>
                    <i className="bx bxs-badge-check"></i> 15GB Memorija po
                    korisniku
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Neograničeno zadataka
                    po korisniku
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Video sostanci
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Timski izveštaj
                  </li>
                  <li>
                    <i className="bx bxs-badge-check"></i> Ofis paket
                  </li>
                </ul>

                <div className="btn-box">
                  <Link to="#" className="default-btn">
                    Izaberite Plan
                    <span></span>
                  </Link>
                </div>
                <sub className="fakturirano">
                  {isActive ? "Fakturisano Mesečno" : "Fakturisano Godišnji"}
                </sub>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    );
  }
}
