import React from "react";
import { Link } from "gatsby";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PricingCards from "../components/SEOAgency/Pricing";
import Footer from "../components/App/Footer";

const MembershipLevels = () => {
  return (
    <Layout>
      <div className="pt-100"></div>
      <Navbar />
      <PricingCards />
      <div className="project-start-area tasksmanager ptb-25 white-textt">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-12">
              <h3>Imate specifične potrebe?</h3>
              <p className="white-textt">
                {" "}
                Ako imate specifične potrebe ili vam je potrebno korporativno
                rešenje, slobodno nas kontaktirajte.
              </p>
            </div>

            <div className="col-lg-1 col-md-12 desktop-only"></div>
            <div className="col-lg-3 col-md-12">
              <div className="project-start-content">
                <Link to="/contact" className="default-btn">
                  Kontaktirajte nas
                  <span></span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="membership-levels-area ptb-100">
        <div className="container">
          <div className="membership-levels-table table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>
                    {/* <span className="price">Features per user</span> */}
                    <h5>Карактеристики по корисник</h5>
                  </th>
                  <th>
                    {/* <span className="price">Free</span> */}
                    <h5>Starter</h5>
                  </th>
                  {/* <th>
                                        <h5>Премиум</h5>
                                    </th> */}
                  <th>
                    {/* <span className="price">Business</span> */}
                    <h5>Standard</h5>
                  </th>
                  <th>
                    {/* <span className="price">Office</span> */}
                    <h5>Biznis</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Korisnic</td>
                  <td>До 5</td>
                  {/* <td>Neograničeno</td> */}
                  <td>Neograničeno</td>
                  <td>Neograničeno</td>
                </tr>
                <tr>
                  <td>Mesečni zadaci</td>
                  <td>20</td>
                  {/* <td>100</td> */}
                  <td>Neograničeno</td>
                  <td>Neograničeno</td>
                </tr>
                <tr>
                  <td>Skladište</td>
                  <td>200MB</td>
                  {/* <td>1GB</td> */}
                  <td>5GB</td>
                  <td>15GB</td>
                </tr>
                <tr>
                  <td>Maksimalna veličina dokumenta</td>
                  <td>10MB</td>
                  {/* <td>50MB</td> */}
                  <td>100MB</td>
                  <td>300MB</td>
                </tr>
                <tr>
                  <td>Tafoma Talk</td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Video sostanci</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td>5 состаноци неделно <br/>(45мин по состанок)</td> */}
                  <td>10 sastanaka nedeljno</td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Gantov grafikon</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Kreiranje timova</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-check"><i className='bx bx-check'></i></td> */}
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Nedeljni/mesečni izveštaji</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Ofis paket</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Timski vebinar</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>

                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <h5>Uskoro...</h5>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>

                <tr>
                  <td>Šabloni projekta</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Tafoma e-mail</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td className="item-none"><i className='bx bx-x'></i></td> */}
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                  <td className="item-check">
                    <i className="bx bx-check"></i>
                  </td>
                </tr>
                <tr>
                  <td>Dodavanje email adresa</td>
                  <td className="item-none">
                    <i className="bx bx-x"></i>
                  </td>
                  {/* <td> 1</td> */}
                  <td> Do 5</td>
                  <td> Do 10</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Footer />
    </Layout>
  );
};

export default MembershipLevels;
